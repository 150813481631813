import {
    INQUIRY_ACCOUNT,
    INQUIRY_ACCOUNT_TOKEN,
    ACTION_LOGIN_ACCOUNT,
    ACTION_TOKEN_B2B2C,
    ACTION_REQUEST_LIVENESS,
    ACTION_LOAD_DATA_TOKEN,
    ACTION_PIN_PAYMENT
} from "../actions/SnapAction";

const SnapState = {
    inqAccount: {},
    inqAccountToken: {},
    loginAccount: {},
    tokenB2B2C: {},
    requestLiveness: {},
    loadDataToken: {},
    pinPayment: {}
};

const SnapReducer = (state = SnapState, action) => {
    let inqAccount;
    let inqAccountToken;
    let loginAccount;
    let tokenB2B2C;
    let requestLiveness;
    let loadDataToken;
    let pinPayment;

    switch (action.type) {
        case INQUIRY_ACCOUNT:
            inqAccount = action.inqAccount;
            return {
                ...state,
                inqAccount,
            };
        case INQUIRY_ACCOUNT_TOKEN:
            inqAccountToken = action.inqAccountToken;
            return {
                ...state,
                inqAccountToken,
            };
        case ACTION_LOGIN_ACCOUNT:
            loginAccount = action.loginAccount;
            return {
                ...state,
                loginAccount,
            };
        case ACTION_TOKEN_B2B2C:
            tokenB2B2C = action.tokenB2B2C;
            return {
                ...state,
                tokenB2B2C,
            };
        case ACTION_REQUEST_LIVENESS:
            requestLiveness = action.requestLiveness;
            return {
                ...state,
                requestLiveness,
            };
        case ACTION_LOAD_DATA_TOKEN:
            loadDataToken = action.loadDataToken;
            return {
                ...state,
                loadDataToken,
            };
        case ACTION_PIN_PAYMENT:
            pinPayment = action.pinPayment;
            return {
                ...state,
                pinPayment,
            };
        default:
            return state;
    }
};
export default SnapReducer
